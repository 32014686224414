<template>
  <ClientOnly>
    <Teleport to="body">
      <div
        v-if="isLoggedIn"
        aria-live="assertive"
        :class="[
          'favorites-notification fixed inset-0 z-50 flex items-end pointer-events-none sm:items-start',
        ]"
      >
        <div class="flex flex-col items-center w-full space-y-4 sm:items-end">
          <transition
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="opacity-100 translate-y-0 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="notificationIsOpen"
              :class="[
                'relative mt-24 w-full overflow-hidden shadow-lg pointer-events-auto grid-cols-1 grid sm:grid-cols-[225px_1fr] bg-black/90 ring-1 ring-black ring-opacity-5 max-w-[500px]',
                processingQueue
                  ? 'cursor-wait cursor-wait-override'
                  : 'pointer-events-auto',
              ]"
            >
              <img
                class="object-cover w-full h-full"
                :src="notificationImageUrl"
              />
              <div class="px-8 py-6 text-white">
                <h3 class="mb-2 text-2xl font-semibold font-figtree">
                  Saved to
                  <NuxtLink
                    to="/my-favorites/"
                    @click="favorites.closeNotification()"
                    class="underline"
                    >Favorites</NuxtLink
                  >
                </h3>
                <p class="mb-2 opacity-80">{{ notificationProductName }}</p>
                <NuxtLink
                  to="/my-favorites/"
                  @click="favorites.closeNotification()"
                  class="button-blue uppercase text-sm px-4 w-auto text-center button button-1 cursor-pointer button-medium !inline-block md:relative"
                >
                  View Your Favorites
                </NuxtLink>
              </div>

              <button
                type="button"
                @click="favorites.closeNotification()"
                class="absolute overflow-hidden text-gray-400 bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-bc-dark-blue focus:ring-offset-2 aspect-square"
              >
                <i
                  class="block text-4xl text-bc-dark-blue relative top-[-1px] icon icon-exit-circle object-cover bg-white"
                ></i>
                <span class="sr-only">Close</span>
              </button>
              <div
                :style="{ width: notificationCountdownProgress + '%' }"
                class="absolute inset-x-0 bottom-0 h-1 bg-fr-secondary-dark transition-all"
              ></div>
            </div>
          </transition>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<style>
.favorites-notification {
  .transition-all {
    transition: all 0.05s;
  }

  .cursor-wait-override * {
    @apply !cursor-wait !pointer-events-none;
  }
}
</style>

<script setup>
import { storeToRefs } from "pinia";
const bcnAuth = useBcnAuth();
const { isLoggedIn } = storeToRefs(bcnAuth);
const favorites = useFavorites();
const {
  notificationImageUrl,
  notificationIsOpen,
  notificationProductName,
  notificationCountdownProgress,
  notificationCurrentInterval,
  processingQueue,
} = storeToRefs(favorites);

onMounted(() => {
  if (notificationIsOpen?.value) {
    favorites.notificationStartCountdown();
  }
});

watch(notificationIsOpen, (newValue) => {
  if (newValue) {
    notificationCountdownProgress.value = 100;
    favorites.notificationStartCountdown();
  }
});
</script>
